<template>
  <div id="Dashboard">
    <v-card flat class="d-flex" outlined>
      <v-card width="300" class="mx-auto" flat>
        <v-navigation-drawer permanent>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                <img
                  alt="Vue logo"
                  class="logo"
                  src="../assets/logo_ezcli.svg"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list v-for="item in items" :key="item.title">
            <v-list-group
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="child in item.items" :key="child.title">
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>

      <div class="dashboard-main d-flex pa-2">
        <h1>{{ msg }}</h1>
        <div>about fuck{{ userProfile }}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  computed: {
    userProfile() {
      return this.$store.state.userProfile;
    },
  },
  data: () => ({
    items: [
      {
        action: "mdi-ticket",
        items: [{ title: "List Item" }],
        title: "Attractions",
      },
      {
        action: "mdi-silverware-fork-knife",
        active: true,
        items: [
          { title: "Breakfast & brunch" },
          { title: "New American" },
          { title: "Sushi" },
        ],
        title: "Dining",
      },
      {
        action: "mdi-school",
        items: [{ title: "List Item" }],
        title: "Education",
      },
      {
        action: "mdi-run",
        items: [{ title: "List Item" }],
        title: "Family",
      },
      {
        action: "mdi-bottle-tonic-plus",
        items: [{ title: "List Item" }],
        title: "Health",
      },
      {
        action: "mdi-content-cut",
        items: [{ title: "List Item" }],
        title: "Office",
      },
      {
        action: "mdi-tag",
        items: [{ title: "List Item" }],
        title: "Promotions",
      },
    ],
  }),
  props: {
    msg: String,
  },
};
</script>

<style lang="css" scoped>
#Dashboard {
  text-align: left;
}
.dashboard-main {
  width: 100%;
}
</style>